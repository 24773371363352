import React from "react";

class Header extends React.Component {
  render() {
    return (
        <div>
            <header className="header">
            <link rel="preconnect" href="https://fonts.gstatic.com"></link>
            <link href="https://fonts.googleapis.com/css2?family=Righteous&display=swap" rel="stylesheet"></link>
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"></link>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>

               <title>JML Digital - Full Service Real Estate Photography</title>
               <meta name="description" content="JML Digital is a full service real estate photography and video company."></meta>
            </header>
        </div>
    )
}
}

export default Header;



      