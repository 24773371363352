import React from 'react'
import { motion } from 'framer-motion';


const Blog = () => {   


    
    return (
        <div>
            
           <motion.div className="smallbanner" transition={{duration: 2}} animate={{ opacity:1, x:0 }} initial={{ opacity:0, x:100}}>
                <a href='/'><img src="images/JMLOrderLogo.png" alt="Welcome to JML Digital Real Estate Marketing Blog" /></a>
            </motion.div>
            <div className='title'>
                JML Digital Blog
            </div>
            <div className='allblogs'>
                <a href='/capturing-success'>
                <div className='oneblog'>
                    <div className='blogtitle'>Capturing Success</div>
                    <div className='blog2ndtitle'> The Importance of High-Quality Visuals in Real Estate Marketing</div>
                    <img src='images/CapturingSuccessImage.jpg'/>
                    <div>
                        <p>In the fast-paced world of real estate, first impressions matter. With the majority of homebuyers starting their property search online, the visual presentation of a listing plays a pivotal role in capturing their attention and interest. In this blog post, we delve into the crucial aspect of real estate marketing... READ MORE
                        </p>
                    </div>
                </div>
                </a>
            </div>
        </div>

        )}
 
export default Blog