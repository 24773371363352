import React from 'react'
import { motion } from 'framer-motion';
import ReactPlayer from 'react-player/youtube';
import { useState } from 'react'

const Front = () => {
            
    const [photolink, setPhotoLink] = useState('images/photoicon.png');

    const photoHover = () => {
        setPhotoLink('images/photoiconblue.png')
    }
    const photoBack = () => {
        setPhotoLink('images/photoicon.png')
    }

    const [videolink, setVideoLink] = useState('images/videoicon.png');

    const videoHover = () => {
        setVideoLink('images/videoiconblue.png')
    }
    const videoBack = () => {
        setVideoLink('images/videoicon.png')
    }

    const [dronelink, setDroneLink] = useState('images/droneicon.png');

    const droneHover = () => {
        setDroneLink('images/droneiconblue.png')
    }
    const droneBack = () => {
        setDroneLink('images/droneicon.png')
    }

    const [threedlink, setThreeDLink] = useState('images/cubeicon.png');

    const threeDHover = () => {
        setThreeDLink('images/cubeiconblue.png')
    }
    const threeDBack = () => {
        setThreeDLink('images/cubeicon.png')
    }

    const [booklink, setBookLink] = useState('images/bookicon.png');

    const bookHover = () => {
        setBookLink('images/bookiconblue.png')
    }
    const bookBack = () => {
        setBookLink('images/bookicon.png')
    }

    const [loginlink, setLoginLink] = useState('images/loginicon.png');

    const loginHover = () => {
        setLoginLink('images/loginiconblue.png')
    }
    const loginBack = () => {
        setLoginLink('images/loginicon.png')
    }

    return (
        
       
        <div>
            <div className='hide'>
            <img src='images/photoiconblue.png'/>
            </div>
            <div className='hide'>
            <img src='images/videoiconblue.png'/>
            </div>
            <div className='hide'>
            <img src='images/droneiconblue.png'/>
            </div>
            <div className='hide'>
            <img src='images/cubeiconblue.png'/>
            </div>
            <div className='hide'>
            <img src='images/bookiconblue.png'/>
            </div>
            <div className='hide'>
            <img src='images/loginiconblue.png'/>
            </div>
            <div className="banner w-1/6 h-full">
                
                 <motion.div className="banner" transition={{duration: 2}} animate={{ opacity:1, y:0 }} initial={{ opacity:0, y:-100}}>
                   
    </motion.div>  
    
                <motion.div className="bannerimg" transition={{duration: 2}} animate={{ opacity:1, x:0 }} initial={{ opacity:0, x:100}}>
                  
                    <img src="images/JMLBanner.png" alt="Welcome to JML Digital" />
                </motion.div> 
                <motion.div className="bannerlogo" transition={{duration: 2}} animate={{ opacity:1, scale:1 }} initial={{ opacity:0, scale:.5}}>
                    <img src="images/JMLWebLogo.png" alt="JML Digital Logo" />
                    </motion.div> 
                
                <div className="bannerhex">
                    <img src="images/JMLBannerHex.png" alt="Welcome to JML Digital" />
                </div>
            </div>
            <a  href="#photolink" >
           <div onMouseOver={photoHover}  onMouseOut={photoBack} >
              <motion.div className="menuiteml" transition={{duration: 2}} animate={{ opacity:1, scale:1 }} initial={{ opacity:0, scale:.5}}>
                <img src={photolink} alt="Real Estate Photography - JML Digital" id="icon1" className="iconimg"/>
              </motion.div> 
            </div>
            </a>
            <a href='#videolink'>
            <div onMouseOver={videoHover}  onMouseOut={videoBack} >
              <motion.div className="menuitem" transition={{duration: 2}} animate={{ opacity:1, scale:1 }} initial={{ opacity:0, scale:.5}}>
                <img src={videolink} alt="Real Estate Video - JML Digital" id="icon3" className="iconimg"/>
              </motion.div> 
            </div>
            </a>
            <a href='#dronelink'>
            <div onMouseOver={droneHover}  onMouseOut={droneBack} >
              <motion.div className="menuitem" transition={{duration: 2}} animate={{ opacity:1, scale:1 }} initial={{ opacity:0, scale:.5}}>
                <img src={dronelink} alt="Real Estate Drone - JML Digital" id="icon2" className="iconimg"/>
              </motion.div> 
            </div>
            </a>
            <a href='#3dlink'>
            <div onMouseOver={threeDHover}  onMouseOut={threeDBack} >
              <motion.div className="menuitem" transition={{duration: 2}} animate={{ opacity:1, scale:1 }} initial={{ opacity:0, scale:.5}}>
                <img src={threedlink} alt="Real Estate 2D, 3D- JML Digital" id="icon4" className="iconimg"/>
              </motion.div> 
            </div>
            </a>
            <a href='http://order.jmldigital.com/order' target='_blank'>
            <div onMouseOver={bookHover}  onMouseOut={bookBack} >
              <motion.div className="menuitemsml" transition={{duration: 2}} animate={{ opacity:1, scale:1 }} initial={{ opacity:0, scale:.5}}>
                <img src={booklink} alt="Real Estate Video - JML Digital" id="icon5" className="iconimg"/>
              </motion.div> 
            </div>
            </a>
            <a href='http://order.jmldigital.com/login' target='_blank'>
            <div onMouseOver={loginHover}  onMouseOut={loginBack} >
              <motion.div className="menuitemsm" transition={{duration: 2}} animate={{ opacity:1, scale:1 }} initial={{ opacity:0, scale:.5}}>
                <img src={loginlink} alt="Real Estate Login - JML Digital" id="icon6" className="iconimg"/>
              </motion.div> 
            </div>
            </a>

            <img src="images/JMLHex1.png" alt="Welcome to JML Digital" />

            <div className="spacesm"></div>
            
            <div className='serv'>
              
                 <h2>Elevate your listings with our comprehensive real estate visual solutions. 
                <br/><br/>
                    From stunning photography and immersive video tours <br/>to captivating drone footage and 2D & 3D renders.
            
                 </h2>
                 <div className="space"></div>

                <div className='buttcenter'>
                 <a href='https://mailchi.mp/49e9a4cfc0f7/sign-up' target='_blank'>
                    <motion.button 
                        whileHover={{scale: 1.1}}
                        className='book-now-button'  
                    >
                        JML DIGITAL NEWS <br/>SIGN-UP
                    </motion.button>
                </a>  
                </div>

                <div className='buttcenter'>
                 <a href='http://order.jmldigital.com' target='_blank'>
                    <motion.button 
                        whileHover={{scale: 1.1}}
                        className='book-now-button'  
                    >
                        BOOK NOW
                    </motion.button>
                </a>  
                </div>

                <div className='buttcenter'>
                 <a href='/blog' >
                    <motion.button 
                        whileHover={{scale: 1.1}}
                        className='book-now-button'  
                    >
                        BLOG
                    </motion.button>
                </a>  
                </div>
                
            <div className="spacesm"></div>
            <div className="spacesm" id="photolink"></div>
            </div>
            <div className='servtitle'>
                <h1>Photography</h1>
            </div>
            <div className="spacesm"></div>
            <div className='serv'>
                <img src="images/photoimg.png" className='servimg' loading='lazy'/>
                <p className='servdes'>JML Digital specializes in capturing the essence of your property through stunning imagery that not only showcases its unique features but also tells a compelling visual story. <br/><br/>Our skilled photographers understand the pivotal role photography plays in selling real estate. <br/><br/>We combine artistic flair with technical expertise to deliver high-quality photographs that make your listings stand out.<br/><br/> Whether you're a real estate agent looking to enhance your marketing materials or a homeowner seeking to highlight your property's potential, we're here to transform your spaces into a visual masterpieces.<br/><br/> Discover how our real estate photography services can elevate your listings and boost your sales.
                </p>
            </div>
            <div className="spacesm"></div>
            <div className="spacesm" id="videolink"></div>
            <div className='servtitle'>
                <h1>Videography</h1>
            </div>
            <div className='serv'>
            <div className="spacesm"></div>
            <div className='servimgr'>
                <div className="youtube-video-container">
                    <ReactPlayer
                    url='https://www.youtube.com/watch?v=WKQkXgLFquo' 
                    className='react-player'
                    width='100%'
                    height='100%'
                    />
              
                </div>
            </div>
                <p className='servdes'>
                Bring your listings to life through captivating video content. Our team specializes in crafting immersive visual experiences that not only to showcase properties but also engage and resonate with potential buyers. <br/><br/> With state-of-the-art equipment and a keen understanding of real estate dynamics, we create high-definition videos that highlight the unique features and charm of each property. <br/><br/>Whether it's a cinematic walkthrough, a virtual tour, or a compelling property showcase, our videos convey the essence of your listings in a way that static images simply cannot match.<br/><br/> Join us in redefining real estate marketing with dynamic video content that helps you connect with your audience on a whole new level. 
                </p>
            </div>
            <div className="spacesm"></div>
            <div className="spacesm" id="dronelink"></div>
            <div className='servtitle'>
                <h1>Drone Photo & Video</h1>
            </div>
            <div className="spacesm"></div>
            <div className='serv'>
                <img src="images/droneimg.png" className='servimg' loading='lazy'/>
                <p className='servdes'>
                Our aerial photography and videography solutions redefine how you showcase properties. <br/><br/>Using cutting-edge drone technology, we capture breathtaking aerial perspectives that give your real estate listings a competitive edge. <br/><br/> With a keen eye for composition and a commitment to detail, our licenced drone pilots ensure every shot tells a compelling story. <br/><br/>Whether you're marketing luxury estates, commercial properties, or land developments, our drone services provide a unique vantage point that highlights key features and the surrounding environment.<br/><br/> Elevate your real estate marketing strategy with stunning aerial imagery that leaves a lasting impression.
                </p>
            </div>
            <div className="spacesm"></div>
            <div className="spacesm" id="3dlink"></div>
            <div className='servtitle'>
                <h1>2D, 3D & Virtual Staging</h1>
            </div>
            <div className="spacesm"></div>
            <div className='serv'>
                <img src="images/3dimg.png" className='servimgr' loading='lazy'/>
                <p className='servdes'>
                Our comprehensive suite of services includes 2D and 3D renderings and virtual staging, all designed to enhance the marketability of your properties.<br/><br/> Our 2D renders provide a birds-eye depiction of the space, offering a glimpse into the potential of the estate as a whole.<br/><br/> Meanwhile, our 3D renders take it a step further, immersing viewers in a lifelike, three-dimensional experience that showcases every detail, inside and out. <br/><br/>Go fully into what is possible with virtual staging that enable you to unlock the full potential of your empty spaces. <br/><br/>We can furnish and decorate rooms virtually, helping buyers visualize their dream homes while maximizing the appeal of your listings and expedite the sales process.
                </p>
            </div>
            <div className="spacesm"></div>
            
           {/* <div className="space"></div>

                <h2>WEB DEVELOPMENT</h2>
                    
                <div className="third">
                <div className="padleft">
                    <a href="https://camilleabrown.org/" target="_blank" rel="noreferrer" >
                    <motion.div whileHover={{scale: 1.1}} className="imgcontainer">
                        <img src="images/CamilleSite.png" alt="Camille A. Brown Site" className="image"/>
                    <div className="middle">
                        <i className="material-icons">link</i> 
                    </div>
                    </motion.div>
                    </a>
                </div>
                </div>

                <div className="third">
                <div className="padcenter">
                    <a href="https://blockwaiter.firebaseapp.com/" rel="noreferrer" target="_blank">
                    <motion.div whileHover={{scale: 1.1}} className="imgcontainer">
                        <img src="images/BlockWaiter.png" alt="BlockWaiter" className="image"/>
                    <div className="middle">
                        <i className="material-icons md-48">link</i> 
                    </div>
                    </motion.div>
                    </a>
                </div>
                </div> 
            
                <div className="third">
                <div className="padright">
                    <a href="http://rennieharrisinc.com/" rel="noreferrer" target="_blank">
                    <motion.div whileHover={{scale: 1.1}} className="imgcontainer">
                        <img src="images/RennieSite.png" alt="Rennie Harris Site" className="image"/>
                    <div className="middle">
                        <i className="material-icons">link</i> 
                    </div>
                    </motion.div>
                    </a>
                </div>
                </div>

            <div className="smspace"></div>

                <motion.h5 whileHover={{scale: 1.1}} >
                    <a href="/websites" target="_blank">
                        See All Sites
                    </a>
                </motion.h5>

            <div className="space"></div>

                <h2>VIDEO PRODUCTION</h2>

                <div className="third">
                <div className="padleft">
                <div className="youtube-video-container">
                    <ReactPlayer
                    url='https://www.youtube.com/watch?v=H3ITO6SkMdw' 
                    className='react-player'
                    width='100%'
                    height='100%'
                    />
                </div>
                </div>
                </div>

                <div className="third">
                <div className="padcenter">
                <div className="youtube-video-container">
                    <ReactPlayer
                    url='https://www.youtube.com/watch?v=P-vyovD1Nu8' 
                    className='react-player'
                    width='100%'
                    height='100%'
                    />
                </div>
                </div>
                </div>

                <div className="third">
                <div className="padright">
                <div className="youtube-video-container">
                    <ReactPlayer
                    url='https://www.youtube.com/watch?v=odTVXunPnGU' 
                    className='react-player'
                    width='100%'
                    height='100%'
                    allowFullScreen
                    />
                </div>
                </div>
                </div>

            <div className="smspace"></div>

                <motion.h5 whileHover={{scale: 1.1}} >
                    <a href="https://www.youtube.com/channel/UCaDK3YCbfM8KahzxzxsH6HQ" rel="noreferrer" target="_blank">
                        Watch More Videos
                    </a>
                </motion.h5>

            <div className="space"></div>

                <h2>GRAPHIC DESIGN</h2>

                <div className="sixth">
                <div className="padleft">
                    <img src="images/ColumbineAnimal.png" alt="Columbine Animal Hospital Advertisement"/>
                </div>
                </div>

                <div className="sixth">
                <div className="padcenter">
                    <img src="images/nobicycleparking.png" alt="No Bicycle Parking Coffee Table Book"/>
                </div>
                </div>

                <div className="sixth">
                <div className="padcenter">
                    <img src="images/20thSealGlow.png" alt="Rennie Harris Puremovement Logo"/>
                </div>
                </div>

                <div className="sixth">
                <div className="padcenter">
                    <img src="images/TonyaLogoR.png" alt="Elan Bird Logo"/>
                </div>
                </div>

                <div className="sixth">
                <div className="padcenter">
                    <img src="images/RHPMStylesheet.png" alt="Rennie Harris Puremovement Brand Stylesheets"/>
                </div>
                </div>

                <div className="sixth">
                <div className="padright">
                    <img src="images/Mr.TolPostcard.png" alt="Camille A. Brown Mr. Tol E. RancE Postard"/>
                </div>
                </div>

            <div className="space"></div> */}
            

        </div>
)}

export default Front
