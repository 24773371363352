import Header from './Components/Header.js';
import Footer from './Components/Footer.js';
import Front from './Components/Front.js';
import Blog from './Components/Blog.js';
import CapturingSuccess from './Components/CapturingSuccess.js'
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


const App = () => {
  return (
    <Router> 
    <div className="App">
    
        <Header />
        <Routes>
          <Route exact path="/"  element={<Front />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/capturing-success" element={<CapturingSuccess />} />
            
        </Routes>
          
        <Footer />
    
    </div>
    </Router>
  );
}

export default App;
